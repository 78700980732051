import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionTelefonica from '../components/pages/VerificacionTelefonica'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";


const VerificacionTelefonicaPage = ({ pageContext, location }) => (

  <GlobalFooterProvider>
  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <VerificacionTelefonica companyName={pageContext.company} pageContext={pageContext} />
      </>
    )}
  />
  </GlobalFooterProvider>
)

export default VerificacionTelefonicaPage
